import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import AvatarComponent from '@shared/ui/display/Avatar';
import { Body } from '@components/styled/Typography';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import { getProfileNameInitials } from '@utils/profile';
const Container = styled.div(() => ({
    display: 'flex',
    width: '100%',
}));
const Avatar = styled(AvatarComponent)(() => ({
    width: 32,
    height: 32,
    minWidth: 32,
    minHeight: 32,
}));
const ClickableContainer = styled.div(props => ({
    display: 'flex',
    cursor: 'pointer',
    minWidth: 0,
    gap: props.theme.spacing_sizes.m,
    alignItems: 'center',
}));
const NicknameText = styled(Body)(() => ({
    fontSize: 12,
    lineHeight: '18px',
}));
const PublicNameText = styled(Body)(() => ({
    lineHeight: '20px',
}));
const UserInfoContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0,
}));
const IdContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'baseline',
    gap: '1ch',
}));
const PublicNameWithEllipsis = withTextEllipsis(PublicNameText);
const NicknameWithEllipsis = withTextEllipsis(NicknameText);
const ProfileThumbnail = ({ userId, avatarImgUrl = undefined, username = undefined, nickname = undefined, onClick = () => { }, }) => {
    const theme = useTheme();
    const isUserHasNickname = useMemo(() => !!username && !!nickname, [username, nickname]);
    const nicknameDisplay = useMemo(() => (nickname ? `@${nickname}` : ''), [nickname]);
    const avatarVariant = useMemo(() => {
        if (avatarImgUrl) {
            return 'img';
        }
        if (!username || !nickname) {
            return 'avatar-icon';
        }
        return 'initials';
    }, [avatarImgUrl, username, nickname]);
    const avatarText = useMemo(() => {
        if (username) {
            return getProfileNameInitials(username);
        }
        return '';
    }, [username]);
    return (_jsx(Container, { children: _jsxs(ClickableContainer, { onClick: onClick, children: [_jsx(Avatar, { variant: avatarVariant, color: 'secondary', imgUrl: avatarImgUrl, text: avatarText }), _jsxs(UserInfoContainer, { children: [isUserHasNickname && (_jsxs(_Fragment, { children: [_jsx(PublicNameWithEllipsis, { tooltipText: username || '', children: username }), _jsx(NicknameWithEllipsis, { color: theme.palette.text.secondary, tooltipText: nicknameDisplay, children: nicknameDisplay })] })), !isUserHasNickname && (_jsxs(IdContainer, { children: [_jsx(Body, { color: theme.palette.text.secondary, children: "id:" }), _jsx(Body, { children: userId })] }))] })] }) }));
};
export default ProfileThumbnail;
